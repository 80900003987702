<template>
    <li class="layout-list-item">
        <router-link 
            :to="'/campaigns/'+ campaign.id"
            class="layout-list-item-btn flex-container p8 pl16"
        >
            <div class="layout-list-item-title nomargin">{{campaign.title ?campaign.title : 'Nome Campagna'}} </div>
        </router-link>
    </li>
</template>
<script>

import {useRouter} from 'vue-router'
export default {
    emits:['list-select'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props,context){
        const router = useRouter()
        const edit = ()=>{
           
            router.push('/campaigns/'+ props.campaign.id)
        }
        const onSelect=()=>{
            context.emit('list-select', props.campaign)
        }
        return{
            edit,
            onSelect,
        }
    }
    
}
</script>
