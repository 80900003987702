<template>
    <div class="layout-content w100 card-grid">
        <div class="layout-content-header p24 pb0">
            <h1 class="nomargin bebas">{{tag.name}}</h1>
            <h3 class="bebas nomargin f24">Campagne associate:</h3>
        </div>
        <ul v-if="tag.campaigns.length > 0 " class="flex-container flex-col nomargin p24  card-content">
            <TagCampaignListElement v-for="campaign in tag.campaigns" :key="campaign.id" :campaign="campaign"></TagCampaignListElement>
        </ul>
        <p v-else class="flex-container flex-col nomargin p24  card-content">
            Nessuna Campagna associata
        </p>
    </div>
</template>
<script>

import TagCampaignListElement from '@/components/tags/elements/TagCampaignListElement'
export default {
    components:{
        TagCampaignListElement
    },
    props:{
        tag:{
            type:Object,
            default:()=>{}
        }
    },
    
}
</script>
<style lang="postcss" scoped>
    .card-grid{
        height:  calc(100vh - 9.15em);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2.8fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
        }

       
    }
</style>