<template>
    <div class="layout-content w100  header-content-grid">
        <div class="layout-content-header p24 pb0">
            <h1 class="nomargin bebas">{{tag.name}}</h1>
            <h3 class="bebas nomargin f24">Pagine social associate:</h3>
        </div>
        <ul v-if="tag.socials.length >0" class="flex-container flex-col nomargin p24  card-content">
            <TagSocialListElement v-for="social in tag.socials" :key="social.socialId" :social="social"></TagSocialListElement>
        </ul>
        <p v-else class="pl24">
            Nessuna pagina associata
        </p>
        
    </div>
</template>
<script>

import TagSocialListElement from '@/components/tags/elements/TagSocialListElement'

export default {
    props:{
        tag:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        TagSocialListElement
    }
}
</script>
<style lang="postcss" scoped>
    .header-content-grid{
        height:  calc(100vh - 9.15em);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 2.8fr;
        gap: 0px 0px;
        grid-template-areas:
            "header"
            "content";
        & .card-header { grid-area: header; }
        & .card-content { 
            grid-area: content; 
            overflow-y: auto;
        }

       
    }
</style>