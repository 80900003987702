<template>
    <div class="flex-container flex-col layout-content w100 flex-grow h100">
        <div class="layout-content-header p24 pb0">
            <h1 class="nomargin bebas">{{tag.name}}</h1>
        </div>
        <div class="flex-container flex-col no-grow pl24 pr24">
            <div class="flex-container mb24 flex-col">
                <label class="flex-container flex-col">
                    <div class="flex-container heading-and-error">
                    <h3 class="bebas nomargin f24">Modifica Nome</h3>
                    </div>
                    
                    <div class="flex-container mt16">
                        <input
                            v-if="editing"  
                            v-model="newName" 
                            type="text" 
                            :placeholder="tag.name"
                            class="noborder nopadding pl8 w100 pb8"
                        />
                        <input
                            v-else 
                            type="text"
                            disabled
                            :placeholder="tag.name"
                            class="noborder nopadding pl8 w100 pb8"
                        />
                        
                    </div>
                </label>
                 
            </div>
        </div>
        <form-footer
            @edit-enable="editing = true"
            @edit-disable="editing = false"
            @submit-form="save"
            @delete-press="onDeleteClick"
            :editing="editing"
            :className="'flex-container no-grow w100 abs panel-footer'"
        ></form-footer>
    </div>
    <ConfirmPanel
        v-if="confirmVisible"
        :title="'Elimina ' + tag.name"
        :message="'sei sicuro di voler eliminare ' + tag.name + '?'"
        @allow-confirm="onDeleteConfirm"
        @deny-confirm="confirmVisible = false"
    >
    </ConfirmPanel>
</template>
<script>
import { ref } from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import ConfirmPanel from '@/components/ConfirmPanel'
import FormFooter from '@/components/formelements/FormFooter'
export default {
    props:{
        tag:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        ConfirmPanel,
        FormFooter
    },
    setup(props){
        const newName = ref(props.tag.name)
        const store = useStore()
        const router = useRouter()
        const editing =ref(false) 
       
        const confirmVisible = ref(false)
        
        const onDeleteClick = async()=>{
            confirmVisible.value=true
        }
        const onDeleteConfirm  = async()=>{
            try {
                await store.dispatch('tags/remove', props.tag.id)
                await store.dispatch('messages/message', {
                    title:'Target eliminato', 
                    message:'hai cancellato  il target ' + props.tag.name
                })
            } catch (error) {
                
                await store.dispatch('messages/message', {
                    title:'errore nella eliminazione del target',
                    message:error.message
                })
            }
            router.push('/tags')
        }

        const save = async() =>{
            try {
               
                await store.dispatch('tags/update', {id:props.tag.id, name:newName.value})
                await store.dispatch('messages/message', {
                    title:'Target Modificato', 
                    message:'hai modificato  il target ' + props.tag.name
                })
            } catch (error) {
                
                await store.dispatch('messages/message', {
                    title:'errore nella modifica del target',
                    message:error.message
                })
            }
        }

        return {
            editing,
            onDeleteClick,
            onDeleteConfirm,
            confirmVisible,
            newName,
            save
        }
    }
}
</script>