<template>
    <li class="layout-list-item">
        <router-link 
            :to="'/social/'+ social.socialId"
            class="layout-list-item-btn flex-container p8 pl16"
        >
            <div class="layout-list-item-title nomargin">{{social.name ? social.name : 'Nome Social'}} </div>
        </router-link>
    </li>
</template>
<script>


export default {
    emits:['list-select'],
    props:{
        social:{
            type:Object,
            default:()=>{}
        }
    },
    setup(props,context){
       
        const onSelect=()=>{
            context.emit('list-select', props.social)
        }
        return{
            onSelect,
        }
    }
    
}
</script>
