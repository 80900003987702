<template>
    <div class="layout-list-header">
        <span class="layout-list-header-title bebas f20 pl16">Target</span>
        <a href="#" class="btn-new" @click.prevent="creatorOpen = true">+</a>
        <CreateTagPanel v-if="creatorOpen" @close="creatorOpen = false"></CreateTagPanel>
    </div>
    <ListHeader
        @input="onInput"
        @change="onSort"
    ></ListHeader>
    <div class="layout-list-content">
        <ul class="layout-list nopadding nomargin">
            <TagListElement 
                v-for="tag in tags" 
                :key= "tag.id" 
                :tag="tag" 
                @list-select="onTagSelect" 
                :selectedId="selectedId"
            >
            </TagListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>

<script>
import {computed,  ref} from 'vue'
import {useStore} from 'vuex'
import TagListElement from '@/components/tags/elements/TagListElement'
import CreateTagPanel from '@/components/tags/panels/CreateTagPanel'
import Pagination from '@/components/Pagination'
import ListHeader from '@/components/ListHeader'

export default {
    props:{
        tags:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:Number,
            default:null
        }
    },
    components:{
        CreateTagPanel,
        TagListElement,
        ListHeader,
        Pagination
    },
    setup(){
        const store = useStore()
        const creatorOpen = ref(false)

        const onTagSelect = async (tag)=>{
            
            await store.dispatch('social/bytag', {tags:[tag]})
            await store.dispatch('campaigns/bytag', {tags:[tag]})
        }

        const onInput = (filter)=>{
            store.dispatch('tags/filter', filter)
        }

        const onSort =(sorting)=>{            
            store.dispatch('tags/sort', sorting)
        }


        const pagination = computed(()=>{
            return store.getters['tags/pagination']
        })

        const onPageChange = async (page)=>{
            await store.dispatch('tags/getTags', {PageNumber:page})
            //await store.dispatch('tags/getSingleTag', props.selectedId)
        }
     
       
        return{
            onTagSelect,
            onInput,
            creatorOpen,
            onSort,
            pagination,
            onPageChange
        }
    }
}
</script>